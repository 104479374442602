<template>
  <el-dialog :lock-scroll="true" :top="'50px'" :width="'60%'" v-model="show" title="Teste"
  >

    




  <template v-slot:title>
  <div style="border-bottom: #e0e0e0 1px solid; padding: 20px;">
    <div class="modal-title" style="display: flex; flex-direction: column; width: calc(100% - 50px); gap: 10px;">
      <!-- Obleas arriba de la barra de búsqueda -->
      <div class="badges-container">
        <div class="badge" :class="{ selected: selectedFilter === 'all' }" @click="filterUsers('all')">
          <span class="badge-text">{{ KT('user.all') }}</span>
          <span class="badge-number">{{ users.length }}</span>
        </div>
        <div class="badge" :class="{ selected: selectedFilter === 'admin' }" @click="filterUsers('admin')">
          <span class="badge-text">{{ KT('user.admins') }}</span>
          <span class="badge-number">{{ adminCount }}</span>
        </div>
        <div class="badge" :class="{ selected: selectedFilter === 'suspended' }" @click="filterUsers('suspended')">
          <span class="badge-text">{{ KT('user.suspended') }}</span>
          <span class="badge-number">{{ suspendedCount }}</span>
        </div>
        <div class="badge" :class="{ selected: selectedFilter === 'debtors' }" @click="filterUsers('debtors')">
          <span class="badge-text">{{ KT('user.debtors') }}</span>
          <span class="badge-number">{{ debtorsCount }}</span>
        </div>
      </div>
      
      <!-- Sección de búsqueda y botones -->
      <div class="search-actions-container">
        <!-- Barra de búsqueda con botón de usuario al lado -->
        <div class="search-bar-container">
          <el-input
            class="search-bar"
            type="text"
            v-model="query"
            :placeholder="KT('user.search')"
            style="flex-grow: 1; height: 40px;">
          </el-input>
          
          <!-- El botón de agregar usuario siempre estará junto a la barra -->
          <el-button
            v-if="store.getters.advancedPermissions(17)"
            @mouseleave="hideTip"
            @mouseenter.stop="showTip($event,KT('user.add'))"
            type="primary"
            @click="editUserRef.newUser()"
            style="height: 40px; padding: 0 15px; min-width: 40px;">
            <i class="fas fa-user-plus"></i>
          </el-button>
        </div>
        
        <!-- Botones de exportación abajo o al lado según resolución -->
        <div class="export-buttons">
          <!-- Botón PDF optimizado -->
          <el-tooltip :content="KT('user.generatePDF')" placement="top" effect="light">
            <el-button 
              @click="generatePdf" 
              type="primary"
              class="export-btn"
              @mouseenter.stop="showTip($event,KT('user.generatePDF'))"
              @mouseleave="hideTip">
              <i class="fas fa-file-pdf"></i>
              <span class="btn-text">{{ KT('user.generatePDF') }}</span>
            </el-button>
          </el-tooltip>
          
          <!-- Botón Excel optimizado -->
          <el-tooltip :content="KT('user.generateExcel')" placement="top" effect="light">
            <el-button 
              @click="generateExcel" 
              type="primary"
              class="export-btn"
              @mouseenter.stop="showTip($event,KT('user.generateExcel'))"
              @mouseleave="hideTip">
              <i class="fas fa-file-excel"></i>
              <span class="btn-text">{{ KT('user.generateExcel') }}</span>
            </el-button>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>










    <template v-slot:footer>
      <div  style="border-top: #e0e0e0 1px solid;padding: 20px;display: flex;justify-content: flex-start;">


          <el-button

              v-if="store.getters.advancedPermissions(19)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('user.remove'))"
              type="danger" :plain="selected===0" @click="doDelete()">
            <i class="fas fa-user-minus"></i>
          </el-button>


          <el-button
              v-if="store.getters.advancedPermissions(18)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('user.edit'))"
              type="warning" :plain="selected===0"  @click="editUserRef.editUser(selected);">
            <i class="fas fa-user-edit"></i>
          </el-button>



            <el-button
                v-if="store.state.server.isPlus && store.state.auth.administrator"
                @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('user.logs'))"
                plain :disabled="selected===0" @click="logObjectsRef.showLogs({userId: selected});">
              <i class="fas fa-clipboard-list"></i>
            </el-button>



          <div style="margin-left: 5px; margin-right: 5px;" :set="user = store.getters['users/getUser'](selected)">
            <el-button
                v-if="store.getters.advancedPermissions(16) && store.getters.advancedPermissions(18)"
                @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('user.users'))"
                plain :disabled="selected===0 || !(user && (user.userLimit===-1 || user.userLimit>0))" @click="linkObjectsRef.showObjects({userId: selected, type: 'users'});">
              <i class="fas fa-users"></i>
            </el-button>
          </div>






          <el-button
              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(8)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('device.devices'))"
              plain :disabled="selected===0"  @click="linkObjectsRef.showObjects({userId: selected, type: 'devices'});">
            <i class="fas fa-car"></i>
          </el-button>



          <el-button
              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(40)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('geofence.geofences'))"
              plain :disabled="selected===0"  @click="linkObjectsRef.showObjects({userId: selected, type: 'geofences'});">
            <i class="fas fa-draw-polygon"></i>
          </el-button>


          <el-button
              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(48)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('group.groups'))"
              plain :disabled="selected===0" @click="linkObjectsRef.showObjects({userId: selected, type: 'groups'});">
            <i class="far fa-object-group"></i>
          </el-button>





          <el-button
              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(32)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('notification.notifications'))"
              plain :disabled="selected===0" @click="linkObjectsRef.showObjects({userId: selected, type: 'notifications'});">
            <i class="far fa-envelope"></i>
          </el-button>



          <el-button
              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(88)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('calendar.calendars'))"
              plain :disabled="selected===0" @click="linkObjectsRef.showObjects({userId: selected, type: 'calendars'});">
            <i class="far fa-calendar-alt"></i>
          </el-button>



          <el-button
              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(64)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('attribute.computedAttributes'))"
              plain :disabled="selected===0" @click="linkObjectsRef.showObjects({userId: selected, type: 'attributes'});">
            <i class="fas fa-server"></i>
          </el-button>



          <el-button

              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(80)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('driver.drivers'))"
              plain :disabled="selected===0" @click="linkObjectsRef.showObjects({userId: selected, type: 'drivers'});">
            <i class="fas fa-user-tag"></i>
          </el-button>



          <el-button
              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(56)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('command.savedCommands'))"
              plain :disabled="selected===0" @click="linkObjectsRef.showObjects({userId: selected, type: 'commands'});">
            <i class="far fa-keyboard"></i>
          </el-button>



          <el-button
              v-if="store.getters.advancedPermissions(18) && store.getters.advancedPermissions(96)"
              @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('maintenance.maintenance'))"
              plain :disabled="selected===0" @click="linkObjectsRef.showObjects({userId: selected, type: 'maintence'});">
            <i class="fas fa-tools"></i>
          </el-button>

          <el-button
               v-if="store.getters.advancedPermissions(16) && store.getters.advancedPermissions(18)"
              @mouseleave="hideTip"
              @mouseenter.stop="showTip($event, KT('user.changesesion'))"
              type="success"
              plain
              :disabled="selected === 0"
              @click="createSession"
            >
            <i class="fas fa-passport"></i>
              
  </el-button>



      </div>
    </template>
    <div class="itm" style="display: flex;background: #eeeeee;">


      <div @click="toggleSorting('id')" style="padding: 10px;width: 30px;font-size: 12px;">
        {{KT('user.id')}}


        <span v-if="sorting==='id-asc'">
          <i class="fas fa-sort-alpha-down"></i>
        </span>
        <span v-else-if="sorting==='id-desc'">
          <i  class="fas fa-sort-alpha-up"></i>
        </span>
        <span v-else>
          <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
        </span>
      </div>

      <div @click="toggleSorting('name')" style="flex: 0.8;padding: 10px;font-size: 12px;">
        {{KT('user.name')}}


        <span v-if="sorting==='name-asc'">
          <i class="fas fa-sort-alpha-down"></i>
        </span>
        <span v-else-if="sorting==='name-desc'">
          <i  class="fas fa-sort-alpha-up"></i>
        </span>
        <span v-else>
          <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
        </span>
      </div>
      <div @click="toggleSorting('email')" style="flex: 0.8;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('user.email')}}


        <span v-if="sorting==='email-asc'">
          <i class="fas fa-sort-alpha-down"></i>
        </span>
        <span v-else-if="sorting==='email-desc'">
          <i  class="fas fa-sort-alpha-up"></i>
        </span>
        <span v-else>
          <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
        </span>
      </div>
      <div @click="toggleSorting('pendingCount')" style="width: 120px;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('invoice.pendingCount')}}
        
        <span v-if="sorting==='pendingCount-asc'">
          <i class="fas fa-sort-numeric-down"></i>
        </span>
        <span v-else-if="sorting==='pendingCount-desc'">
          <i class="fas fa-sort-numeric-up"></i>
        </span>
        <span v-else>
          <i style="color: silver;" class="fas fa-sort-numeric-down"></i>
        </span>
      </div>
      <div @click="toggleSorting('pendingBalance')" style="width: 120px;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('invoice.pendingBalance')}}
        
        <span v-if="sorting==='pendingBalance-asc'">
          <i class="fas fa-sort-numeric-down"></i>
        </span>
        <span v-else-if="sorting==='pendingBalance-desc'">
          <i class="fas fa-sort-numeric-up"></i>
        </span>
        <span v-else>
          <i style="color: silver;" class="fas fa-sort-numeric-down"></i>
        </span>
      </div>
      <div @click="toggleSorting('lastDueDate')" style="width: 150px;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('invoice.lastDueDate')}}
        
        <span v-if="sorting==='lastDueDate-asc'">
          <i class="fas fa-sort-alpha-down"></i>
        </span>
        <span v-else-if="sorting==='lastDueDate-desc'">
          <i class="fas fa-sort-alpha-up"></i>
        </span>
        <span v-else>
          <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
        </span>
      </div>
      <div @click="toggleSorting('administrator')" style="width: 90px;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('user.admin')}}


        <span v-if="sorting==='administrator-asc'">
          <i class="fas fa-sort-alpha-down"></i>
        </span>
        <span v-else-if="sorting==='administrator-desc'">
          <i  class="fas fa-sort-alpha-up"></i>
        </span>
        <span v-else>
          <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
        </span>
      </div>
      <div @click="toggleSorting('disabled')" style="width: 92px;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('user.disabled')}}


        <span v-if="sorting==='disabled-asc'">
          <i class="fas fa-sort-alpha-down"></i>
        </span>
        <span v-else-if="sorting==='disabled-desc'">
          <i  class="fas fa-sort-alpha-up"></i>
        </span>
        <span v-else>
          <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
        </span>
      </div>
    </div>
    <div style="height: calc(100vh - 300px); overflow: hidden; overflow-y: scroll;">



      <div class="itm" v-for="(u,k) in filteredUsers" :key="k" @click="selected = (selected!==u.id)?u.id:0" :class="{tr1: (k%2),tr2: !(k%2),selected: (selected === u.id)}" style="display: flex;">

        <div style="width: 30px;padding: 10px;font-size: 14px;">{{u.id}}</div>
        <div style="flex: 0.8;padding: 10px;font-size: 14px;">{{u.name}}</div>
        <div style="flex: 0.8;padding: 10px;font-size: 14px;text-align: center;">{{u.email}}</div>
        <div style="width: 120px;padding: 10px;font-size: 14px;text-align: center;">{{getPendingInvoices(u.id)}}</div>
        <div style="width: 120px;padding: 10px;font-size: 14px;text-align: center;">{{formatCurrency(getPendingBalance(u.id))}}</div>
        <div style="width: 150px;padding: 10px;font-size: 14px;text-align: center;">{{getLastDueDate(u.id)}}</div>
        <div style="width: 90px;padding: 10px;font-size: 14px;text-align: center;">{{(u.administrator)?$t('yes'):$t('no')}}</div>
        <div style="width: 90px;padding: 10px;font-size: 14px;text-align: center;">{{(u.disabled)?$t('yes'):$t('no')}}</div>
      </div>

    </div>

  </el-dialog>



</template>


<script setup>
// Función para mostrar el tooltip (ya debes tener esta función definida en otro lugar)

// Función para ocultar el tooltip (ya debes tener esta función definida en otro lugar)

// Método para crear la sesión para el usuario seleccionado
const createSession = async () => {
  if (selected.value === 0) {
    return; // Si no hay usuario seleccionado, no hacer nada
  }

  try {
    // Realizar la llamada a la API
    const response = await axios.get("/api/session/"+selected.value);
    
    
    // Aquí puedes manejar la respuesta, por ejemplo:
    console.log('Sesión creada con éxito:', response.data);
    
    // Puedes redirigir al usuario o hacer lo que sea necesario después de la creación de sesión
    window.location.reload()
  } catch (error) {
        console.error('Error al crear sesión:', error);
     }
};

import axios from 'axios';





import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/checkbox/style/css'

import {ElDialog,ElMessage,ElButton,ElInput} from "element-plus";

import {ref,defineExpose,inject,computed,onMounted} from 'vue';
import {useStore} from 'vuex'
import { jsPDF } from 'jspdf';  // Importar jsPDF
import {ElMessageBox} from "element-plus/es/components/message-box";
import {ElNotification} from "element-plus/es/components/notification";
import KT from '../../func/kt.js';

const showTip = (evt,text)=>{
  window.$showTip(evt,text);
}

const hideTip = (evt,text)=>{
  window.$hideTip(evt,text);
}

const store = useStore();
const query = ref('');
const selected = ref(0);
const show = ref(false);

const editUserRef = inject('edit-user');
const linkObjectsRef = inject('link-objects');
const logObjectsRef = inject('log-objects');


const sorting = ref('id-asc');

const toggleSorting = (s)=>{
  const p = sorting.value.split("-");

  if(p[0]===s){
    sorting.value = s+'-'+((p[1]==='asc')?'desc':'asc');
  }else{
    sorting.value = s+'-asc';
  }


}
const selectedFilter = ref('all'); 

const filteredUsers = computed(() => {
  let filtered = [...users.value];

  // Aplicar filtro según la oblea seleccionada
  if (selectedFilter.value === 'admin') {
    filtered = filtered.filter(user => user.administrator === true);
  } else if (selectedFilter.value === 'suspended') {
    filtered = filtered.filter(user => user.disabled === true);
  } else if (selectedFilter.value === 'debtors') {
    filtered = filtered.filter(user => getPendingBalance(user.id) > 0);
  }

  // Filtrado por búsqueda
  filtered = filtered.filter((f) => {
    // Incluir el pendingBalance en los criterios de búsqueda
    const pendingBalanceStr = formatCurrency(getPendingBalance(f.id));
    const pendingInvoicesStr = String(getPendingInvoices(f.id));
    
    if (
      pendingBalanceStr.toLowerCase().includes(query.value.toLowerCase()) ||
      pendingInvoicesStr.toLowerCase().includes(query.value.toLowerCase())
    ) {
      return true;
    }
    
    for (let k of Object.keys(f)) {
      if (String(f[k]).toLowerCase().match(query.value.toLowerCase())) {
        return true;
      }
    }
    for (let k of Object.keys(f.attributes)) {
      if (String(f.attributes[k]).toLowerCase().match(query.value.toLowerCase())) {
        return true;
      }
    }
    return false;
  });

  // Ordenamiento
  const p = sorting.value.split("-");
  return filtered.sort((a, b) => {
    // Ordenar por saldo pendiente
    if (p[0] === 'pendingBalance') {
      const balanceA = getPendingBalance(a.id);
      const balanceB = getPendingBalance(b.id);
      
      if (p[1] === 'asc') {
        return balanceA - balanceB;
      } else {
        return balanceB - balanceA;
      }
    } else if (p[0] === 'lastDueDate') {
      // Obtener las fechas de último vencimiento para ambos usuarios
      let dateA = 0;
      let dateB = 0;
      
      if (userInvoices.value[a.id] && userInvoices.value[a.id].lastDueDate) {
        dateA = userInvoices.value[a.id].lastDueDate;
      }
      
      if (userInvoices.value[b.id] && userInvoices.value[b.id].lastDueDate) {
        dateB = userInvoices.value[b.id].lastDueDate;
      }
      
      if (p[1] === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    } else if (p[0] === 'pendingCount') {
      const countA = getPendingInvoices(a.id);
      const countB = getPendingInvoices(b.id);
      
      if (p[1] === 'asc') {
        return countA - countB;
      } else {
        return countB - countA;
      }
    } else if (p[0] === 'administrator' || p[0] === 'disabled') {
      if (p[1] === 'asc') {
        return (a[p[0]] === true && b[p[0]] === false) ? 1 : -1;
      } else {
        return (a[p[0]] === true && b[p[0]] === false) ? -1 : 1;
      }
    } else if (p[0] === 'name') {
      if (p[1] === 'asc') {
        return a[p[0]].localeCompare(b[p[0]]);
      } else {
        const t = a[p[0]].localeCompare(b[p[0]]);
        return (t === 1) ? -1 : ((t === -1) ? 1 : 0);
      }
    } else if (!a[p[0]] || !b[p[0]]) {
      return (p[1] === 'desc') ? 1 : -1;
    } else if (p[0] === 'created_at') {
      if (new Date(a[p[0]]) < new Date(b[p[0]])) {
        return (p[1] === 'asc') ? 1 : -1;
      } else if (new Date(a[p[0]]) > new Date(b[p[0]])) {
        return (p[1] === 'desc') ? -1 : 1;
      } else {
        return 0;
      }
    } else if (a[p[0]] > b[p[0]]) {
      return (p[1] === 'asc') ? 1 : -1;
    } else if (a[p[0]] < b[p[0]]) {
      return (p[1] === 'desc') ? 1 : -1;
    } else {
      return 0;
    }
  });
});

const doDelete = () => {

// Verificar si se ha seleccionado un usuario
if (selected.value === 0) {
  ElMessage.error(KT('user.selectUser')); // Usar KT para traducción
  return false;
}

// Verificar si el usuario está intentando eliminarse a sí mismo
if (selected.value === store.state.auth.id) {
  ElMessage.error(KT('user.cannotDeleteSelf')); // Usar KT para traducción
  return false;
}

// Obtener el usuario seleccionado
const user = store.getters["users/getUser"](selected.value);

// Verificar si el usuario es un administrador superior
if (user.id < store.state.auth.id && user.administrator) {
  ElMessage.error(KT('user.cannotDeleteAdmin')); // Usar KT para traducción
  return false;
}

// Mostrar el mensaje de confirmación
ElMessageBox.confirm(
  KT('user.confirmDelete', { name: user.name }), // Usar KT para traducción
  KT('user.dangerTitle'), // Usar KT para traducción
  {
    confirmButtonText: KT('user.delete'), // Usar KT para traducción
    confirmButtonClass: 'danger',
    cancelButtonText: KT('user.cancel'), // Usar KT para traducción
    type: 'warning',
  }
).then(() => {
  // Ejecutar la acción de eliminación
  store.dispatch("users/deleteUser", selected.value).then(() => {
    ElNotification({
      title: KT('user.successTitle'), // Usar KT para traducción
      message: KT('user.successMessage'), // Usar KT para traducción
      type: 'success',
    });
    selected.value = 0;
  }).catch((e) => {
    ElNotification({
      title: KT('user.errorTitle'), // Usar KT para traducción
      message: e.response.data || KT('user.errorMessage'), // Usar KT para traducción
      type: 'danger',
    });
  });
}).catch(() => {
  ElMessage.error(KT('user.actionCancelled')); // Usar KT para traducción
})

}

const showUsers = ()=>{
    try {
        // Primero cargar los usuarios, luego mostrar el diálogo y cargar facturas
        store.dispatch('users/getUsers').then(() => {
            show.value = true;
            loadUserInvoices(); // Cargar información de facturas al abrir
            console.log('Vista de usuarios abierta correctamente');
        }).catch(error => {
            console.error('Error al cargar usuarios:', error);
        });
    } catch (error) {
        console.error('Error al abrir la vista de usuarios:', error);
        // Intentar recuperarse del error
        setTimeout(() => {
            store.dispatch('users/getUsers').then(() => {
                show.value = true;
                loadUserInvoices();
            });
        }, 100);
    }
}


defineExpose({
  showUsers



})

const users = computed(() => store.getters['users/getUsers']);
//const query = ref('');
//const filterType = ref('all');

// Cargar datos iniciales
onMounted(() => {
  loadUserInvoices();
});


const adminCount = computed(() => {
  return users.value.filter(user => user.administrator === true).length;
});

const suspendedCount = computed(() => {
  return users.value.filter(user => user.disabled === true).length;
});

const debtorsCount = computed(() => {
  return users.value.filter(user => getPendingBalance(user.id) > 0).length;
});

const filterUsers = (filter) => {
  selectedFilter.value = filter;
};

// Datos de facturas de usuarios
const userInvoices = ref({});

// Función para cargar facturas de todos los usuarios
const loadUserInvoices = () => {
  fetch("/tarkan/invoices/manager").then((response) => {
    response.json().then((json) => {
      // Transformar datos para acceso rápido por ID
      const invoicesMap = {};
      json.forEach(user => {
        // Filtrar facturas pendientes o vencidas
        const pendingInvoices = user.invoices.filter(inv => ['PENDING', 'OVERDUE'].includes(inv.status));
        // Encontrar la factura con la fecha de vencimiento más reciente
        let lastDueDate = 0;
        if (pendingInvoices.length > 0) {
          lastDueDate = Math.max(...pendingInvoices.map(invoice => invoice.DueDateTime || 0));
        }
        
        invoicesMap[user.id] = {
          pendingCount: pendingInvoices.length,
          pendingBalance: pendingInvoices.reduce((acc, inv) => acc + inv.value, 0),
          lastDueDate: lastDueDate
        };
      });
      userInvoices.value = invoicesMap;
    });
  });
};

// Obtener cantidad de facturas pendientes para un usuario
const getPendingInvoices = (userId) => {
  if (userInvoices.value[userId]) {
    return userInvoices.value[userId].pendingCount;
  }
  return 0;
};

// Obtener saldo pendiente para un usuario
const getPendingBalance = (userId) => {
  if (userInvoices.value[userId]) {
    return userInvoices.value[userId].pendingBalance;
  }
  return 0;
};

// Obtener fecha del último vencimiento para un usuario
const getLastDueDate = (userId) => {
  if (userInvoices.value[userId] && userInvoices.value[userId].lastDueDate) {
    return new Date(userInvoices.value[userId].lastDueDate * 1000).toLocaleDateString();
  }
  return KT('no');
};

// Formatear moneda
const formatCurrency = (value) => {
  try {
    // Asegurarse de que value sea un número
    const numValue = Number(value) || 0;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(numValue);
  } catch (error) {
    console.error('Error al formatear moneda:', error);
    return 'R$ 0,00'; // Valor predeterminado en caso de error
  }
};

// Calcular el total de deuda de manera segura
const calculateTotalDebt = (users) => {
  return users.reduce((total, user) => {
    const balance = getPendingBalance(user.id);
    return total + (isNaN(balance) ? 0 : Number(balance));
  }, 0);
};





const generatePdf = () => {
  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4'
  });

  // Calcular el total de la deuda de manera segura
  const totalDebt = calculateTotalDebt(filteredUsers.value);

  // Obtener la categoría seleccionada
  let categoryName = KT('user.all');
  if (selectedFilter.value === 'admin') {
    categoryName = KT('user.admins');
  } else if (selectedFilter.value === 'suspended') {
    categoryName = KT('user.suspended');
  } else if (selectedFilter.value === 'debtors') {
    categoryName = KT('user.debtors');
  }

  // Título del informe
  doc.setFontSize(18);
  doc.text(KT('user.reportTitle'), 20, 20);
  
  // Información adicional
  doc.setFontSize(12);
  doc.text(KT('user.reportCategory', { category: categoryName }), 20, 30);
  doc.text(KT('user.reportTotalUsers', { count: String(filteredUsers.value.length) }), 20, 36);
  doc.text(KT('user.reportTotalDebt', { value: formatCurrency(totalDebt) }), 20, 42);

  // Encabezado de la tabla
  const headers = [
    KT('user.ID'), 
    KT('user.Nombre'), 
    KT('user.Correo'), 
    KT('invoice.pendingCount'),
    KT('invoice.pendingBalance'),
    KT('user.Administrador'), 
    KT('user.Bloqueado'), 
    KT('user.Telefono'), 
    KT('user.CPF-CNPJ')
  ];
  
  const data = filteredUsers.value.map(user => [
    user.id,
    user.name,
    user.email,
    getPendingInvoices(user.id),
    formatCurrency(getPendingBalance(user.id)),
    user.administrator ? KT('yes') : KT('no'),
    user.disabled ? KT('yes') : KT('no'),
    user.phone,
    user.attributes['tarkan.billingCpfCnpj'],
  ]);

  // Agregar tabla al PDF
  doc.autoTable({
    head: [headers],
    body: data,
    startY: 50, // Iniciar la tabla debajo de la información adicional
    margin: { top: 10 },
  });

  // Descargar el PDF
  doc.save("informe_usuarios.pdf");
};

import * as XLSX from 'xlsx';

const generateExcel = () => {
  // Calcular el total de la deuda de manera segura
  const totalDebt = calculateTotalDebt(filteredUsers.value);

  // Obtener la categoría seleccionada
  let categoryName = KT('user.all');
  if (selectedFilter.value === 'admin') {
    categoryName = KT('user.admins');
  } else if (selectedFilter.value === 'suspended') {
    categoryName = KT('user.suspended');
  } else if (selectedFilter.value === 'debtors') {
    categoryName = KT('user.debtors');
  }
  
  // Crear los encabezados y la información del reporte
  const reportInfo = [
    [KT('user.reportTitle')],
    [KT('user.reportCategory', { category: categoryName })],
    [KT('user.reportTotalUsers', { count: String(filteredUsers.value.length) })],
    [KT('user.reportTotalDebt', { value: formatCurrency(totalDebt) })],
    [] // Línea en blanco
  ];
  
  const headers = [
    KT('user.ID'), 
    KT('user.Nombre'), 
    KT('user.Correo'), 
    KT('invoice.pendingCount'),
    KT('invoice.pendingBalance'),
    KT('user.Administrador'), 
    KT('user.Bloqueado'), 
    KT('user.Telefono'), 
    KT('user.CPF-CNPJ')
  ];
  
  const data = filteredUsers.value.map(user => [
    user.id,
    user.name,
    user.email,
    getPendingInvoices(user.id),
    formatCurrency(getPendingBalance(user.id)),
    user.administrator ?  KT('yes') : KT('no'),
    user.disabled ? KT('yes') : KT('no'),
    user.phone,
    user.attributes['tarkan.billingCpfCnpj'],
  ]);

  // Crear una hoja de trabajo (worksheet) incluyendo la información del reporte
  const ws = XLSX.utils.aoa_to_sheet([...reportInfo, headers, ...data]);

  // Crear un libro de trabajo (workbook)
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Usuarios');

  // Generar el archivo Excel y descargarlo
  XLSX.writeFile(wb, 'informe_usuarios.xlsx');
};

</script>

<style>

/* Estilos originales */
.itm {
  border-bottom: silver 1px dotted;
}

.itm div {
  border-right: silver 1px dotted;
}

.tr1 {
  background: #f3f3f3;
}

.tr2 {
  background: #f8f8f8;
}

.selected {
  background: rgba(174, 174, 174, 0.05) !important;
}

.itm div:last-child {
  border-right: none;
}

.el-select.el-select--large {
  width: 100%;
}

.el-dialog__header, .el-dialog__body, .el-dialog__footer {
  padding: 0px !important;
}

.el-dialog__footer {
  margin-top: 0px;
}

.el-tabs__nav-wrap {
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content {
  padding-left: 20px;
  padding-right: 20px;
}

.danger {
  --el-button-text-color: var(--el-color-danger) !important;
  --el-button-bg-color: #fef0f0 !important;
  --el-button-border-color: #fbc4c4 !important;
  --el-button-hover-text-color: var(--el-color-white) !important;
  --el-button-hover-bg-color: var(--el-color-danger) !important;
  --el-button-hover-border-color: var(--el-color-danger) !important;
  --el-button-active-text-color: var(--el-color-white) !important;
  --el-button-active-border-color: var(--el-color-danger) !important;
}

.badges-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  min-width: 200px;
  border-radius: 20px;
  background-color: #d1cfcf;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.badge-text {
  flex-grow: 1;
  text-align: left;
}

.badge:hover {
  background-color: #393e879f;
}

.badge-count {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.badge.selected {
  background-color: #c2c1c1;
  color: rgb(52, 20, 212);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.badge-number {
  background-color: #f2f0f0;
  border-radius: 50%;
  padding: 5px 10px;
  min-width: 30px;
  text-align: center;
  margin-left: 10px;
  font-size: 14px;
  color: #555;
}

.user-header {
  width: 100%;
}

.header-container {
  border-bottom: #e0e0e0 1px solid;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.badges-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 5px;
}

.badge {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  background-color: #f5f7fa;
  transition: background-color 0.3s;
}

.badge.selected {
  background-color: #e6f1fc;
  color: #409eff;
}

.badge-number {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2px 8px;
  font-size: 0.85em;
}

.search-actions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search-bar {
  text-align: center;
  margin-bottom: 0px;
  flex-grow: 1;
  width: 100%;
}

.action-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.add-user-btn, .generate-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MODIFICADO: ESTILOS PARA LA DISTRIBUCIÓN DE LOS ELEMENTOS */
.search-bar-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap; /* Cambiado a nowrap para mantener los elementos en línea */
  width: 100%;
}

/* Grupo de botones de exportación, separado del botón de usuario */
.export-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
}

/* Estilo para botones de exportación */
.export-btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: all 0.3s;
}

.export-btn i {
  font-size: 16px;
}

/* Ajuste responsivo para botones */
@media (max-width: 768px) {
  .export-btn {
    min-width: 40px;
    width: 40px;
    padding: 0;
  }
  
  .export-btn .btn-text {
    display: none;
  }
  
  /* Modificado: Mantener el botón de usuario junto a la barra */
  .search-bar-container {
    flex-wrap: nowrap;
  }
  
  /* Nuevos estilos: Organizar los botones de exportación debajo */
  .export-buttons {
    margin-top: 10px;
    width: 100%;
    justify-content: flex-end;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .export-btn {
    padding: 0 10px;
  }
  
  .export-btn .btn-text {
    display: none;
  }
}

/* Para pantallas más grandes, mostramos el texto */
@media (min-width: 993px) {
  .btn-text {
    display: inline;
  }
}

/* Estilos para tooltips */
.el-tooltip__popper {
  max-width: 200px;
  font-size: 12px;
}

/* Estilos responsivos originales - modificados para mantener el botón de usuario junto a la barra */
@media (min-width: 768px) {
  .search-actions-container {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (max-width: 576px) {
  .badges-container {
    justify-content: center;
  }
  
  .export-buttons {
    justify-content: center;
    width: 100%;
  }
  
  .search-bar-container {
    flex-wrap: nowrap; /* Mantiene el botón junto a la barra */
  }
}

@media (min-width: 992px) {
  .header-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .badges-container {
    margin-bottom: 0;
  }
  
  .search-actions-container {
    max-width: 70%;
  }
  
  .search-bar-container {
    flex-wrap: nowrap;
  }
}
</style>
